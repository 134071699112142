body{
    color: #ffffff;
}

/*Styling of amazon signout*/
.Nav__navBar___xtCFA {
    width: 265px;
    height: 50px;
    background-color: #33333500;
    border: 0px;
    position: fixed;
    bottom: 0;
    z-index: 1;
}
.Nav__navItem___1LtFQ {
    display: none;
}
button.Button__button___vS7Mv {
    width: 225px;
    margin: 0 auto 15px;
    display: block;
    border-radius: 8px;
}
.Nav__nav___2Dx2Y {
    padding: 0px;
}
.Nav__navRight___1QG2J {
    text-align: left;
}
.Button__button___vS7Mv {
    min-width: 80px;
}
.Button__signInButtonIcon___ihN75 {
    display: none;
}
button#Button__googleSignInButton___1YiCu {
    font-family: sofia-pro, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}
button#Button__facebookSignInButton___34Txh {
    font-family: sofia-pro, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    border-bottom: 0px;
    border-right: 0px;
}
._530- {
    background-image: url(/rsrc.php/v3/y0/r/iXwrYnS92WB.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 0 0;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 80px;
    margin: -40px 0 0 -40px;
    outline: none;
    padding: 0;
    width: 0 !important;
}
._1o0y {
    background-image: url(/rsrc.php/v3/y1/r/MR6McuIiR60.png);
    background-repeat: no-repeat;
    background-size: 69px 477px;
    background-position: 0 0;
    height: 68px;
    margin: -34px 0 0 -34px;
    width: 0 !important;
}
#privacy-policy p.intro {
    margin-left: 0;
}
span.truncate-alias-member {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

/* =============================================
    TRANSFER MANAGER 
============================================== */
:root{
    --transfer-height: 90vh;
    --transfer-height-close: 85px;
    --head-height: 72px;
    --file-counter-height: 48px;
}
.w-80 {
    width: 82% !Important;
}
body.modal-open-transfer .App {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
    height: 100vh;
}
.transfer-manager {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1001;
	padding: 0 .5em;
	display: flex;
	justify-content: center;
	align-items: center;
    opacity: 0;
    transform: scale(.8);
    transition: .5s ease-in-out;
}
.transfer-manager.is-open {
    position: fixed;
    width: 100%;
    height:100%;
    left: 0;
    bottom: 0;
    z-index: 1001;
    padding: 0 .5rem;
    opacity: 1;
    transform: scale(1);
}
.transfer-manager-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background: red;
}
.container-transfer-manager {
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	width: 100%;
}
.container-transfer-manager .card {
    padding: 0;
}
.transfer-mamanger-trigger {
    position: fixed;
    right: 1rem;
    bottom: 5rem;
}
.transfer-mamanger-trigger-button-wrap {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: flex-end;
    padding:0;
}
span.files-counter-trigger {
    background: rgb(5 23 53);
    box-shadow: 0 0.25rem 0.25rem var(--opacity-b-03);
    border: 1px solid #0062ff;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    font-size: 0.875em;
    right: 3rem;
}
.transfer-mamanger-trigger button:hover {
    background: var(--DarkBluish);
    box-shadow: 0 10px 10px -5px rgb(0 0 0 / 30%);
}
.transfer-manager .card {
	background-color: rgb(40 40 44);
	border-radius: .5rem;
	margin: 0 auto;
	width: 100%;
	box-shadow: 0 15px 50px -15px rgb(0 0 0 / 70%);
	transition: .3s ease-in-out;
}
.transfer-manager .card-header {
    padding: 0;
    margin-bottom: 0;
    border-bottom: 0 solid transparent;
    border-radius: 1em 1em 0 0 !important;
    height: var(--head-height);
    cursor: initial;
}
.transfer-manager.is-open .card-header {
    box-sizing: border-box;
    height: var(--head-height);
}
.transfer-manager.is-open .card {
    height:var(--transfer-height);
}
.transfer-manager.is-open .container-transfer-manager {
	max-width: 1100px;
	margin: 0 auto;
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
}
.transfer-manager.is-open .transfer-manager-overlay {
    background: rgb(0 0 0 / 20%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}
.transfer-manager-card.is-open.card {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
}

.transfer-manager .card-body {
    height: calc(var(--transfer-height) - var(--head-height));
    padding: .75rem;
    box-sizing: border-box;
    padding-bottom: 0;
    overflow: scroll;
}
.progrees-bar-unactive{
    border-radius: 2em;
}

/* ======== MOVE FILES PANEL ======== */


.move-files-folder{
    border-left: 4px solid var(--Blue) !important;
}
.wrapper-choose-file-shadow {
	position: relative;
	height: 100%;
    width: 100%;
	box-sizing: content-box;
}
.wrapper-choose-file-shadow:after {
    content: "";
    width: 100%;
    height: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #222;
    background: linear-gradient(180deg,rgb(40 40 44 / 0%) 6%,#28282c 100%);
    pointer-events: none;
}
.move-files-folder {
    border-left: 5px solid var(--Blue);
}


/* ======== UPLOADED FILES ======== */

.scrollabe-container {
    height: auto;
    overflow: inherit;
    padding-bottom: 2em;
}
.wrapper-choose-file {
    height: calc(var(--transfer-height) - var(--head-height) - var(--file-counter-height) - 5.5rem);
    overflow-y: scroll;
    position: relative;
}
button.addmore {
    border-style: dashed;
    border-width: 1px;
}
button.addmore:hover {
    border-color: var(--opacity-w-03);
    background: var(--opacity-b-03);
    color: var(--opacity-w-09);
}
#progress-file-uploader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
}
#progress-file-uploader .delic-progress {
    width: 100%;
    position: absolute;
    border-radius: 0;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
}
#progress-file-uploader .delic-progress.progress {
    background-color: transparent;
    border-radius: 0;
}
#progress-file-uploader .delic-progress .progress-bar {
    border-radius: 0;
    height: .25em;
    position: absolute;
    bottom: 0;
    background-color: var(--Blue);
}


/* ======== UPLOADED FILES ======== */

.file-upload-label {
    white-space: nowrap;
    width: 94%;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.9;
}
.option-label {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--opacity-b-09);
}
.uploader-file-container {
    background: var(--opacity-b-01);
    border-radius: 5px;
    padding: .5em .25em;
}

.uploader-file-name input {
    background: transparent;
    border: none;
    color: var(--opacity-w-09);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 26px;
    min-width: 216px;
}
.cursor-pointer input:hover {
    cursor: pointer;
}
.cursor-pointer input:focus {
    cursor: auto;
}
.version-dropdown .dropdown-menu.show {
    background: #BCBCBC;
    border: none;
    max-width: 100%;
    width: 100%;
    transform: translate(0px, 48px) !important;
}
.version-dropdown input.form-control {
    width: calc(100%) !important;
    border: none !important;
    background: var(--opacity-b-01);
    margin: 0 !important;
    padding: 1.5em;
}

.version-dropdown  .list-unstyled {
    overflow: scroll;
    height: 50vh;
}
.version-dropdown .dropdown-item {
    padding: .25rem .25rem .25rem 1.5rem;
    cursor: pointer;
}
.version-dropdown .dropdown-item.active, 
.version-dropdown .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--opacity-w-03);
}
.version-dropdown .dropdown-item:focus, 
.version-dropdown .dropdown-item:hover {
    text-decoration: none;
    background-color: var(--opacity-w-01);
    color: var(--opacity-b-09);
}
.blue-dot-selected {
    background-color: var(--Blue) !important;
    color: #fff;
}
.delic-popover-delete-file{
    display: block;
}

ul.list-unstyled .dropdown-header:first-child {
    display: none;
}
.orginize-panel {
    max-height: 100%;
    overflow: auto;
    padding-bottom: 6em;
    padding-right: 0;
}
.organize-panel-wrapper:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 40px;
    bottom: 0;
    background: rgb(40 40 44);
    background: linear-gradient(0deg, rgb(40 40 44) 0%, rgb(40 40 44 / 0%) 100%);
}
.transfer-button-section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
    background: #28282c;
    padding: 1em;
}

@media (min-width: 768px) {
    
    .transfer-mamanger-trigger {
        position: fixed;
        right: 1rem;
        bottom: 1rem;
    }
    .transfer-manager .card {
        border-radius: 1rem;
    }
    .transfer-manager .card-body {
        padding: 1em;
        overflow: inherit;
    }
    .uploader-file-name input {
        min-width: 256px;
    }
  
    .left-splitter{
        border-left: 1px solid var(--opacity-w-01);
        height: 100%;
    }
    .bodyTransfer {
        height: 100%;
        overflow: hidden;
    }
    .scrollabe-container {
        height: calc(100vh - 120px - 2rem - 72px);
        overflow: auto;
        padding-bottom: 10em;
    }
    .transfer-button-section {
        position: absolute;
        width: calc(100% - 1.5em);
        padding: .75rem 15px 1rem 0;
        left: 1.5em;
    }
    .wrapper-choose-file {
        height: calc(var(--transfer-height) - var(--head-height) - var(--file-counter-height) - 7.5rem);
    }
    .delic-popover-delete-file {
        z-index: 10000;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .delic-popover-delete-file .popover-body{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        width:40px;
        background-color:transparent;
        margin: 0 !important;
        border-radius: 5px;
        transform: translateX(-.3em);
    }
    .delic-popover-delete-file .arrow{
        display: none;
        width:0 !important;
    }
    .delic-popover-delete-file .arrow::after,
    .delic-popover-delete-file .arrow::before {
        display: none;
    }
    .orginize-panel {
        padding-right: 15px;
    }

}
@media (min-width: 992px) {
    .uploader-file-container {
        background: var(--opacity-0);
        padding: .25em .25em .25em 0;
    }
    .transfer-mamanger-trigger {
        position: fixed;
        right: calc(var(--Right-Side-Panel) + 3rem);
        bottom: 1.5rem;
    }
}
@media (min-width: 1201px) {
    .transfer-manager.is-open {
        width: 100%;
        padding: 0 1rem;
    }
    .transfer-manager-card.is-open.card {
        max-width: 1100px;
    }
}



/* ========================
    VISUALIZER
======================== */

.work-card.work-card-open.card {
    border-radius: 0 0 .5em .5em ;
}
.close-viewer {
    height: 0;
    opacity: 0;
}
.open-viewer{
    opacity: 1;
}
.open-viewer img{
    cursor: zoom-in;
}
.player-wrapper {
    width: 101%;
    height: 101%;
}
/* LightBox */
img.lightbox-image {
	border-radius: .5em;
	margin: 0 auto;
	max-width: 100%;
	width: auto;
	max-height: 376px;
	align-self: center;
}
.zoom-cursor {
    cursor: zoom-in;
}
.lb-header .lb-button {
    position: relative;
    border-radius: 5rem;
}
.lb-header .lb-button:hover {
    background-color: var(--opacity-w-01) !important;
}
.lb-header .lb-button:hover:before {
    color: var(--opacity-w-09) !important;
}
.lb-icon-close{
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-times fa-w-10'%3E%3Cpath fill='white' d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z' class=''%3E%3C/path%3E%3C/svg%3E") !important;
}
.lb-icon-arrow{
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='arrow-left' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-arrow-left fa-w-14 fa-xs'%3E%3Cpath fill='white' d='M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z' class=''%3E%3C/path%3E%3C/svg%3E") !important;
}
.lb-icon-rotate{
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='undo' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-undo fa-w-16 fa-fw fa-2x'%3E%3Cpath fill='white' d='M20 8h10c6.627 0 12 5.373 12 12v110.625C85.196 57.047 165.239 7.715 256.793 8.001 393.18 8.428 504.213 120.009 504 256.396 503.786 393.181 392.834 504 256 504c-63.926 0-122.202-24.187-166.178-63.908-5.113-4.618-5.354-12.561-.482-17.433l7.069-7.069c4.503-4.503 11.749-4.714 16.482-.454C150.782 449.238 200.935 470 256 470c117.744 0 214-95.331 214-214 0-117.744-95.331-214-214-214-82.862 0-154.737 47.077-190.289 116H180c6.627 0 12 5.373 12 12v10c0 6.627-5.373 12-12 12H20c-6.627 0-12-5.373-12-12V20c0-6.627 5.373-12 12-12z' class=''%3E%3C/path%3E%3C/svg%3E") !important;;
}
.lb-icon-zoomin{
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='search-plus' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-search-plus fa-w-16 fa-9x'%3E%3Cpath fill='white' d='M319.8 204v8c0 6.6-5.4 12-12 12h-84v84c0 6.6-5.4 12-12 12h-8c-6.6 0-12-5.4-12-12v-84h-84c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h84v-84c0-6.6 5.4-12 12-12h8c6.6 0 12 5.4 12 12v84h84c6.6 0 12 5.4 12 12zm188.5 293L497 508.3c-4.7 4.7-12.3 4.7-17 0l-129-129c-2.3-2.3-3.5-5.3-3.5-8.5v-8.5C310.6 395.7 261.7 416 208 416 93.8 416 1.5 324.9 0 210.7-1.5 93.7 93.7-1.5 210.7 0 324.9 1.5 416 93.8 416 208c0 53.7-20.3 102.6-53.7 139.5h8.5c3.2 0 6.2 1.3 8.5 3.5l129 129c4.7 4.7 4.7 12.3 0 17zM384 208c0-97.3-78.7-176-176-176S32 110.7 32 208s78.7 176 176 176 176-78.7 176-176z' class=''%3E%3C/path%3E%3C/svg%3E") !important;
}
.lb-icon-zoomout{
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='search-minus' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-search-minus fa-w-16'%3E%3Cpath fill='white' d='M307.8 223.8h-200c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12zM508.3 497L497 508.3c-4.7 4.7-12.3 4.7-17 0l-129-129c-2.3-2.3-3.5-5.3-3.5-8.5v-8.5C310.6 395.7 261.7 416 208 416 93.8 416 1.5 324.9 0 210.7-1.5 93.7 93.7-1.5 210.7 0 324.9 1.5 416 93.8 416 208c0 53.7-20.3 102.6-53.7 139.5h8.5c3.2 0 6.2 1.3 8.5 3.5l129 129c4.7 4.7 4.7 12.3 0 17zM384 208c0-97.3-78.7-176-176-176S32 110.7 32 208s78.7 176 176 176 176-78.7 176-176z' class=''%3E%3C/path%3E%3C/svg%3E") !important;
}
.lb-icon-reset{
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='repeat' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-repeat fa-w-16 fa-9x'%3E%3Cpath fill='white' d='M512 256c0 88.225-71.775 160-160 160H110.628l68.201 68.201c4.686 4.686 4.686 12.284 0 16.971l-5.656 5.656c-4.686 4.686-12.284 4.686-16.971 0l-98.343-98.343c-4.686-4.686-4.686-12.284 0-16.971l98.343-98.343c4.686-4.686 12.284-4.686 16.971 0l5.656 5.656c4.686 4.686 4.686 12.284 0 16.971L110.628 384H352c70.579 0 128-57.421 128-128 0-28.555-9.403-54.952-25.271-76.268-3.567-4.792-3.118-11.462 1.106-15.686l5.705-5.705c5.16-5.16 13.678-4.547 18.083 1.271C499.935 186.438 512 219.835 512 256zM57.271 332.268C41.403 310.952 32 284.555 32 256c0-70.579 57.421-128 128-128h241.372l-68.201 68.201c-4.686 4.686-4.686 12.284 0 16.971l5.656 5.656c4.686 4.686 12.284 4.686 16.971 0l98.343-98.343c4.686-4.686 4.686-12.284 0-16.971L355.799 5.172c-4.686-4.686-12.284-4.686-16.971 0l-5.656 5.656c-4.686 4.686-4.686 12.284 0 16.971L401.372 96H160C71.775 96 0 167.775 0 256c0 36.165 12.065 69.562 32.376 96.387 4.405 5.818 12.923 6.432 18.083 1.271l5.705-5.705c4.225-4.224 4.674-10.893 1.107-15.685z' class=''%3E%3C/path%3E%3C/svg%3E") !important;
}
